import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import './termsOfUse.scss'

const TermsOfUse = () => {
  return (
    <Layout seo={{
      title: 'Terms Of Use'
    }}>
      <div className="container">
        <section className="terms">
          <header>
            <h1 className="terms__title">Terms Of Use</h1>
          </header>

          <article>
            <p>These Terms and Conditions, which include our Privacy Policy, set out the terms of the agreement between you and ALLEGRO PTY LTD for your use of our website.</p>
            <p>By using our website, you unreservedly accept these Terms and Conditions of Use, including our Privacy Policy (together our <span className="bold">TOU</span>), and you agree that your use of our website, including any purchase of goods from us, is subject to our TOU. </p>
            <p>These TOU cannot be varied without our written consent. We may update these TOU from time to time and the current version will always be shown on the TOU page of our website. We encourage you to read these TOU, and to <Link className="terms__link" to="/#contacts">contact us</Link> if you have any particular questions. If you do not agree to these TOU, you must not use our website in any manner.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Accessing our website</h4>
            <p>We will not be liable if for any reason our website is unavailable at any time or for any period. From time to time, we may need to restrict access to our website (wholly or partly). </p>
            <p>You are responsible for making all arrangements necessary for your own access to our website. You are also responsible for ensuring that all persons who access our website through your Internet connection are aware of these TOU, and that they comply with them.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Our product</h4>
            <p>We endeavour to ensure that all the products on our website, including our offers platform are accurately described, and we rely on information provided to us by our suppliers. Unfortunately, on some occasions it is possible that our website may contain errors and we reserve the right to correct any errors or inaccuracies at any time. images on our site are generic in nature and do not necessarily correspond exactly to the product offered.</p>
            <p>To the extent permitted by applicable law, we do not warrant that the product descriptions, colours, information or other content available or offered on our website or offers platform are accurate, complete, reliable, current or error-free, nor do we make any warranty about the standard or quality of any of the products offered via our website or offers platform.</p>
            <p>On rare occasions our suppliers find themselves unable to deliver products to us, or the products delivered are not of sufficient quality. We reserve the right to withdraw any products from our website or offers platform at any time and/or remove or edit any materials or content on our website. Whilst we will make our best efforts to process all orders, there may be exceptional circumstances which mean that we may need to refuse to process an order after we have sent you an order confirmation, which we reserve the right to do at any time, at our sole discretion. We will not be liable to you or any other third party by reason of our withdrawing any product from our website or offer platform, whether it has been sold or not, removing or editing any materials or contents on our website or offers platform, or for refusing to process or accept an order after we have sent you the order confirmation.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Your order</h4>
            <p className="bold">All orders are to be confirmed in writing between the buyer and the seller. Neither the website nor the offers platform is used to confirm any orders.</p>
            <p>The sale contract is therefore concluded in Western Australia.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Intellectual property</h4>
            <p>We are the owner or the licensee of all intellectual property rights (including, without limitation, trademarks and copyright) in our website, and in the material published on it (including, without limitation, its look and feel, brands, logos and trademarks, information, graphics, photographs, content, images, sounds, video, audio, text, layout, logos, button icons and the compilation and organisation thereof, and all intellectual property rights contained therein). Those works are protected by intellectual property laws around the world. All such rights are reserved.</p>
            <p>You must not scrape or modify the paper or digital copies of any materials you have printed off, downloaded or extracted in any way, and you must not use any content separately from any accompanying text. Our status (and that of any identified contributors) as the author of material on our website must always be acknowledged.</p>
            <p>You must not use any part of the materials on our website, services or e-commerce facilities for commercial purposes without obtaining a licence to do so from us or our licensors. If you print off, copy or download any part of our website in breach of these TOU, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You are not permitted to copy, publish or use any of the content in any way, whether personal or commercial, without our prior written permission.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Third party websites</h4>
            <p>Where our website contains links or references to other sites, resources, products or services provided by third parties, these links or references are provided for your information and convenience only. We have no control over the contents of those sites, resources, products or services, and accept no responsibility for them or for any loss or damage that may arise from your use of them (notwithstanding anything else contained herein).</p>
            <p>Your linking to or from these sites, or use of, or reliance on such sites, resources, products or services is at your own risk. You should carefully review the terms and conditions and privacy policies of all off-website pages and other websites or third party suppliers that you visit or transact with. Links, references or other connections to these sites, resources, products or services do not imply any endorsement of them or any association with their owners, operators or advertising material (as the context permits).</p>
            <p>You may link to our home page provided your links do not damage our reputation or take advantage of it, and you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you.</p>
            <p>Our website must not be framed on any other site. Our site must not be utilised by third party web crawlers (or similar) in order to scrape content from our site for any purpose. We reserve the right to withdraw linking permission without notice.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Viruses and hacking</h4>
            <p>You must not misuse our website by knowingly introducing viruses, trojans or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our website, the server on which our website is stored or any server, computer or database connected to our website.</p>
            <p>Whilst we do our best to ensure that our website is virus-free, you acknowledge that the Internet and e-commerce facilities may attract unknown contingencies out of our control. Accordingly, we will not be liable for any loss or damage caused by a virus, system failures or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our website or to your downloading of any material posted on it, or on any website linked to it. We always recommend all Internet users ensure they have up to date virus checking software installed.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Email communication</h4>
            <p>You consent to receive communication from us electronically, either by email or by us posting notices on our website and you agree that all communications that we provide to you electronically will satisfy any legal requirement that a communication be in writing.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Force majeure</h4>
            <p>We shall not be responsible for any delay, suspension or failure arising out of any circumstances outside of our reasonable control, including but not limited to, acts of God, governmental actions, shipping, postal or other relevant transport strike, postal theft, failure or accident, lockouts or other labour difficulty, war or national emergency, acts of terrorism, fire, explosion, flood, an act or omission of a third party, inability to obtain any necessary materials, equipment, facilities or services, the failure of performance provided by others, interruption of the internet or a website (such as Facebook) or virus, accidents or breakdown of plant, machinery, software, hardware or communication network.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Applicable law</h4>
            <p>These TOU, including our Privacy Policy are governed by the laws of Western Australia and are subject to the non-exclusive jurisdiction of the courts of Western Australia. Our website is available only to people who can form legally binding contracts under applicable law.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Variations</h4>
            <p>We may revise these TOU at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Some of the provisions contained in these TOU may also be superseded by provisions or notices published elsewhere on our website. Any changes are effective immediately upon posting to our website. Your continued use of our website thereafter constitutes your agreement to all such changed TOU. Please read these TOU before placing an order online and check back often. If you do not agree to any change, then you must immediately stop using our website.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Relationship</h4>
            <p>No agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended or created between you and us by these TOU.</p>
          </article>

          <article>
            <h4 className="terms__subtitle">Severability</h4>
            <p>If any provision in these TOU is found to be invalid or unenforceable by a court of law, such invalidity or unenforceability will not affect the remainder of these TOU which will continue in full force and effect.</p>
          </article>

        </section>
      </div>
    </Layout>
  )
}

export default TermsOfUse
